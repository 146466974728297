<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-form style="padding: 20px 20px 0" :model="queryForm" ref="queryForm" inline>
        <el-form-item label="艺术家昵称">
          <el-input
            v-model="queryForm.artist_name"
            placeholder="请输入艺术家昵称"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="艺术家UCID">
          <el-input
            v-model="queryForm.artist_ucid"
            placeholder="请输入艺术家UCID"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="福袋名称">
          <el-input
            v-model="queryForm.lucky_bag_name"
            placeholder="请输入福袋名称"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单号">
          <el-input
            v-model="queryForm.order_id"
            placeholder="请输入订单号"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="买家昵称">
          <el-input
            v-model="queryForm.buyer_name"
            placeholder="请输入买家昵称"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>
        <el-form-item label="买家UCID">
          <el-input
            v-model="queryForm.buyer_ucid"
            placeholder="请输入买家UCID"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>

        <el-form-item label="订单时间">
          <el-date-picker
            v-model="timeRange"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="timestamp"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="责任商务">
          <el-input
            v-model="queryForm.business_contact"
            placeholder="请输入责任商务"
            @keyup.enter.native="handleClickSearch"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="handleClickSearch">查询</el-button>
          <el-button type="info" @click="handleClickReset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-table :data="list" border stripe row-key="user_id">
      <el-table-column label="订单号" prop="order_id"> </el-table-column>
      <el-table-column label="艺术家" prop="artist_name"> </el-table-column>
      <el-table-column label="艺术家UCID" prop="artist_ucid"> </el-table-column>
      <el-table-column label="买家名" prop="buyer_name"> </el-table-column>
      <el-table-column label="买家UCID" prop="buyer_ucid"> </el-table-column>
      <el-table-column label="福袋名称" prop="lucky_bag_name"> </el-table-column>
      <el-table-column label="购买数量" prop="buy_num"> </el-table-column>
      <el-table-column label="支付金额" prop="payment_money"> </el-table-column>
      <el-table-column label="支付渠道" prop="payment_channel_type"> </el-table-column>
      <el-table-column label="渠道成本" prop="payment_channel_cost"> </el-table-column>
      <el-table-column label="订单时间" prop="order_time">
        <template slot-scope="scope">
          <div>{{ scope.row.order_time | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="责任商务" prop="business_contact"> </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getLuckyBagSaleStatisticList } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      queryForm: {
        // promotion_type: "",
        // status: "",
      },
      list: [],
      timeRange: [],
    };
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    getPatamsFromQueryForm() {
      let params = {};
      Object.keys(this.queryForm).forEach((key) => {
        if (this.queryForm[key]) {
          params[key] = this.queryForm[key];
        }
      });
      if (this.timeRange.length) {
        params.order_start_at = Math.floor(this.timeRange[0] / 1000);
        params.order_end_at = Math.floor(this.timeRange[0] / 1000);
      }
      return params;
    },
    async fetchData() {
      try {
        const params = {
          ...this.getPatamsFromQueryForm(),
          page: this.page,
          page_size: this.pageSize,
        };
        const { data } = await getLuckyBagSaleStatisticList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {}
    },
    handleClickSearch() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
    handleClickReset() {
      this.resetQuery();
      this.fetchData();
    },
    resetQuery() {
      this.page = 1;
      this.pageSize = 20;
      this.timeRange = [];
      this.queryForm = {};
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    handleStatusChange() {
      this.page = 1;
      this.pageSize = 20;
      this.fetchData();
    },
  },
};
</script>
